import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["image", "dialog", "zoomedImage", "download", "share", "title"];

  open(event) {
    event.preventDefault();

    this.dialogTarget.showModal();
    this.dialogTarget.classList.toggle("hidden");
    this.element.classList.toggle("overflow-y-hidden");
    this.#set(event.target.closest("a"));
  }

  reset() {
    this.dialogTarget.classList.toggle("hidden");
    this.element.classList.toggle("overflow-y-hidden");
    this.zoomedImageTarget.src = "";
    this.downloadTarget.href = "";
    this.shareTarget.dataset.webShareFilesValue = "";
  }

  #set(target) {
    this.zoomedImageTarget.src = target.href;
    this.titleTarget.textContent = target.dataset.title;
  }
}
