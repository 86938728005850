// app/javascript/controllers/revealable_controller.js

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  toggle(event) {
    const triggerElement = event.currentTarget;

    // Get the toggle identifiers
    const toggleIdentifiers = triggerElement.dataset.toggle.split(",").map((id) => id.trim());

    // Toggle elements by identifiers
    toggleIdentifiers.forEach((identifier) => {
      const toggleElements = document.querySelectorAll(`[data-toggleable-id="${identifier}"]`);
      toggleElements.forEach((element) => {
        element.classList.toggle("hidden");
      });
    });
  }
}
