export function htmlTruncate(html, maxLength, options = {}) {
  const {
    buffer = 50,
    ellipsis = "...",
    preserveElements = ["a", "strong", "em", "code"],
    ariaLabel = "Content is truncated",
    customBoundaries = {},
  } = options;

  const div = document.createElement("div");
  div.innerHTML = html;

  const plainText = div.textContent || div.innerText || "";
  const totalLength = plainText.length;

  if (totalLength <= maxLength) {
    return { html, truncated: false };
  }

  let currentLength = 0;
  let truncated = false;
  let lastParagraphEnd = 0;
  let lastSentenceEnd = 0;
  let lastWordEnd = 0;
  let truncationPoint = 0;
  const openElements = [];

  function isCustomBoundary(text, type) {
    if (customBoundaries[type]) {
      return customBoundaries[type].test(text);
    }
    return false;
  }

  function traverse(node) {
    if (node.nodeType === Node.TEXT_NODE) {
      const textContent = node.textContent;
      const words = textContent.match(/\S+\s*/g) || [];

      for (let i = 0; i < words.length; i++) {
        const word = words[i];
        const newLength = currentLength + word.length;

        if (newLength > maxLength + buffer) {
          truncated = true;
          break;
        }

        currentLength = newLength;
        lastWordEnd = currentLength;

        if (word.match(/[.!?](\s|$)/) || isCustomBoundary(word, "sentence")) {
          lastSentenceEnd = currentLength;
        }
      }

      if (truncated) {
        let endIndex;
        if (lastParagraphEnd > maxLength - buffer) {
          endIndex = lastParagraphEnd;
        } else if (lastSentenceEnd > maxLength - buffer) {
          endIndex = lastSentenceEnd;
        } else {
          endIndex = lastWordEnd;
        }

        truncationPoint = endIndex;
        return document.createTextNode(textContent.slice(0, endIndex - currentLength + textContent.length));
      }

      return node.cloneNode(true);
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const elementClone = node.cloneNode(false); // Clone without children

      if (preserveElements.includes(node.tagName.toLowerCase())) {
        openElements.push(elementClone);
      }

      if (node.tagName === "P" || isCustomBoundary(node.textContent, "paragraph")) {
        lastParagraphEnd = currentLength;
      }

      for (let child of node.childNodes) {
        if (truncated) break;
        const childClone = traverse(child);
        if (childClone) elementClone.appendChild(childClone);
      }

      if (preserveElements.includes(node.tagName.toLowerCase())) {
        openElements.pop();
      }

      if ((node.tagName === "P" || isCustomBoundary(node.textContent, "paragraph")) && !truncated) {
        lastParagraphEnd = currentLength;
      }

      return elementClone;
    }
    return null;
  }

  const truncatedDiv = traverse(div);

  // Check if the difference between full and truncated content is within the buffer
  if (totalLength - truncationPoint <= buffer) {
    return { html, truncated: false };
  }

  // Close any open preserved elements
  while (openElements.length) {
    const element = openElements.pop();
    element.appendChild(document.createTextNode(ellipsis));
    truncatedDiv.appendChild(element);
  }

  let truncatedHtml = truncatedDiv.innerHTML; // Use innerHTML instead of outerHTML

  // Add ARIA label for accessibility
  if (truncatedHtml.length < html.length) {
    truncatedHtml = `<div aria-label="${ariaLabel}">${truncatedHtml}</div>`;
  } else {
    truncated = false;
  }

  return {
    html: truncated ? truncatedHtml : truncatedHtml,
    truncated,
  };
}
