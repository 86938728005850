import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "dialog", "overlay"];

  open(event) {
    event.preventDefault();
    const url = event.currentTarget.getAttribute("href") || event.currentTarget.dataset.href;
    const frame = event.currentTarget.dataset.modalFrame || "modal-content";
    console.log("FRAME:::::: ", url, frame);
    // console.log("OVERLAY TARGET::::: ", this.overlayTarget);
    this.overlayTarget.classList.toggle("hidden");
    this.element.classList.toggle("overflow-y-hidden");

    // Fetch content from the URL
    fetch(url)
      .then((response) => response.text())
      .then((html) => {
        // Inject the fetched content into the content target
        this.contentTarget.innerHTML = html;
      })
      .catch((error) => {
        console.error("Error loading content:", error);
      });

    // const adjacentElement = event.target.nextElementSibling;

    // console.log("VISITING TURBO FRAME::::: ", url, frame);

    // // Wait for the frame to load completely
    // document.addEventListener(
    //   "turbo:frame-load",
    //   (event) => {
    //     console.log("TURBO FRAME LOADED:::::: ", event.target);
    //     if (event.target.id === frame) {
    //       console.log("EVENT TARGET::::: ", event.target.id);
    //       console.log("ADJACENT ELEMENT:::: ", adjacentElement);
    //       // This arrow function ensures that `adjacentElement` and `this` are properly captured
    //       this.contentTarget.innerHTML = adjacentElement.innerHTML;
    //       console.log("Content set to:", adjacentElement.innerHTML);
    //     }
    //   },
    //   { once: true }
    // ); // Listen once to the event, then stop listening

    // Turbo.visit(url, { frame: frame });
  }

  close() {
    this.overlayTarget.classList.toggle("hidden");
    this.element.classList.toggle("overflow-y-hidden");
    this.contentTarget.innerHTML = "";
  }
}
