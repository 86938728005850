// app/javascript/controllers/select_input_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "button", "dropdown", "selectedText", "checkmark"];

  connect() {
    // Close dropdown when clicking outside
    console.log("CONNECTING SELECT INPUT CONTROLLER:::::: ");
    document.addEventListener("click", this.handleClickOutside.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.handleClickOutside.bind(this));
  }

  toggleDropdown(event) {
    event.stopPropagation();

    if (this.dropdownTarget.classList.contains("hidden")) {
      // Show dropdown
      this.dropdownTarget.classList.remove("hidden");
      // Start enter transition after a frame to ensure removal of hidden is processed
      requestAnimationFrame(() => {
        this.dropdownTarget.classList.add("transition-opacity", "duration-100", "ease-out");
        this.dropdownTarget.classList.remove("opacity-0");
        this.dropdownTarget.classList.add("opacity-100");
      });
    } else {
      // Hide dropdown with transition
      this.hideDropdown();
    }
  }

  hideDropdown() {
    // Add transition classes
    this.dropdownTarget.classList.add("transition-opacity", "duration-100", "ease-in");
    // Start transition to opacity-0
    this.dropdownTarget.classList.remove("opacity-100");
    this.dropdownTarget.classList.add("opacity-0");

    // Wait for transition to complete before hiding
    setTimeout(() => {
      this.dropdownTarget.classList.add("hidden");
      // Clean up transition classes
      this.dropdownTarget.classList.remove("transition-opacity", "duration-100", "ease-in", "opacity-0");
    }, 100); // Match duration-100
  }

  selectOption(event) {
    const value = event.currentTarget.dataset.value;
    const label = event.currentTarget.dataset.label;
    console.log("OPTION BEING SELECTED:::::: ", value, label);
    // Update hidden select
    this.selectTarget.value = value;
    this.selectTarget.dispatchEvent(new Event("change"));

    // Update visible text
    this.selectedTextTarget.textContent = label;

    // Update checkmarks
    this.checkmarkTargets.forEach((checkmark) => {
      checkmark.classList.add("hidden");
    });
    event.currentTarget.querySelector("[data-select-input-target='checkmark']").classList.remove("hidden");

    // Hide dropdown with transition
    this.hideDropdown();
  }

  updateSelected() {
    // This method can be used to handle programmatic changes to the select
    console.log("updateSelected:::::: ");
    const selectedOption = this.selectTarget.options[this.selectTarget.selectedIndex];
    if (selectedOption) {
      this.selectedTextTarget.textContent = selectedOption.text;
    }
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.hideDropdown();
    }
  }
}
