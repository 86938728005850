import { Controller } from "@hotwired/stimulus";
import { htmlTruncate } from "../utils/html_truncate"; // Import the utility function

export default class extends Controller {
  static targets = ["content", "toggleContentButton"];

  static values = {
    maxSnippetLength: Number,
    buffer: { type: Number, default: 50 },
  };

  connect() {
    this.contentTargets.forEach((contentTarget) => {
      const fullHtml = contentTarget.innerHTML;
      const textContent = contentTarget.textContent || "";

      if (textContent.length > this.maxSnippetLengthValue) {
        this.storeFullHtml(contentTarget, fullHtml);
        const truncated = this.storeTruncatedContent(contentTarget, fullHtml);

        if (truncated) {
          this.showToggleContentButton(contentTarget);
        } else {
          this.hideToggleContentButton(contentTarget);
        }
      } else {
        this.hideToggleContentButton(contentTarget);
      }
    });
  }

  storeFullHtml(contentTarget, fullHtml) {
    contentTarget.dataset.fullHtml = fullHtml;
  }

  storeTruncatedContent(contentTarget, fullHtml) {
    // Only run the truncation once and store the result
    if (!contentTarget.dataset.truncatedHtml) {
      const { html: truncatedHtml, truncated } = htmlTruncate(fullHtml, this.maxSnippetLengthValue, {
        buffer: this.bufferValue,
      });

      if (truncated) {
        contentTarget.dataset.truncatedHtml = truncatedHtml;
        // Set the initial state to truncated
        contentTarget.innerHTML = truncatedHtml;
        contentTarget.dataset.expanded = "false";
        return true;
      } else {
        // If not truncated, keep the full HTML
        contentTarget.innerHTML = fullHtml;
        contentTarget.dataset.expanded = "true";
        return false;
      }
    }
    return contentTarget.dataset.expanded === "false";
  }

  showToggleContentButton(contentTarget) {
    const toggleContentButton = contentTarget.closest(".expandable-item").querySelector(".toggle-content");
    if (toggleContentButton) {
      toggleContentButton.classList.remove("hidden");
      toggleContentButton.textContent = "Read More";
    }
  }

  hideToggleContentButton(contentTarget) {
    const toggleContentButton = contentTarget.closest(".expandable-item").querySelector(".toggle-content");
    if (toggleContentButton) {
      toggleContentButton.classList.add("hidden");
    }
  }

  toggleContent(event) {
    const contentTarget = event.target.closest(".expandable-item").querySelector(".content");
    const isExpanded = contentTarget.dataset.expanded === "true";
    const toggleContentButton = event.target;

    if (isExpanded) {
      // Use the already stored truncated HTML
      contentTarget.innerHTML = contentTarget.dataset.truncatedHtml;
      contentTarget.dataset.expanded = "false";
      toggleContentButton.textContent = "Read More";
    } else {
      // Use the already stored full HTML
      contentTarget.innerHTML = contentTarget.dataset.fullHtml;
      contentTarget.dataset.expanded = "true";
      toggleContentButton.textContent = "Read Less";
    }
  }
}
