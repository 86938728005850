export class RsvpStorage {
  constructor() {
    this.dbName = "RsvpSystem";
    this.dbVersion = 1;
    this.storeName = "rsvps";
    this.initPromise = this.initDB();
  }

  async initDB() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, this.dbVersion);

      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(request.result);

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains(this.storeName)) {
          const store = db.createObjectStore(this.storeName, { keyPath: "id" });
          store.createIndex("eventId", "eventId", { unique: false });
          store.createIndex("email", "email", { unique: false });
        }
      };
    });
  }

  async saveRsvp(rsvpData) {
    const db = await this.initPromise;
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([this.storeName], "readwrite");
      const store = transaction.objectStore(this.storeName);

      const request = store.put({
        id: `${rsvpData.eventId}-${rsvpData.id}`,
        eventId: rsvpData.eventId,
        rsvpId: rsvpData.id,
        email: rsvpData.email,
        timestamp: new Date().toISOString(),
        expiresAt: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(), // 30 days
      });

      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  }

  async getRsvpForEvent(eventId) {
    const db = await this.initPromise;
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([this.storeName], "readonly");
      const store = transaction.objectStore(this.storeName);
      const index = store.index("eventId");

      const request = index.get(eventId);

      request.onsuccess = () => {
        const result = request.result;
        if (result && new Date(result.expiresAt) > new Date()) {
          resolve(result);
        } else if (result) {
          // Clean up expired entry
          this.removeRsvp(`${eventId}-${result.rsvpId}`);
          resolve(null);
        } else {
          resolve(null);
        }
      };
      request.onerror = () => reject(request.error);
    });
  }

  async getAllRsvps() {
    const db = await this.initPromise;
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([this.storeName], "readonly");
      const store = transaction.objectStore(this.storeName);
      const request = store.getAll();

      request.onsuccess = () => {
        // Filter out expired RSVPs
        const currentRsvps = request.result.filter((rsvp) => new Date(rsvp.expiresAt) > new Date());
        resolve(currentRsvps);
      };
      request.onerror = () => reject(request.error);
    });
  }

  async removeRsvp(id) {
    const db = await this.initPromise;
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([this.storeName], "readwrite");
      const store = transaction.objectStore(this.storeName);
      const request = store.delete(id);

      request.onsuccess = () => resolve();
      request.onerror = () => reject(request.error);
    });
  }

  async cleanup() {
    const rsvps = await this.getAllRsvps();
    const now = new Date();

    for (const rsvp of rsvps) {
      if (new Date(rsvp.expiresAt) <= now) {
        await this.removeRsvp(rsvp.id);
      }
    }
  }
}
