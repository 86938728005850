import { Controller } from "@hotwired/stimulus";
import { RsvpStorage } from "../utils/rsvp_storage";

export default class extends Controller {
  static targets = ["form", "searchForm", "status", "guestCount", "errors", "rsvpList"];
  static values = {
    eventId: Number,
  };

  connect() {
    this.storage = new RsvpStorage();
    this.checkExistingRsvp();

    if (this.hasStatusTarget) {
      this.toggleGuestCount();
    }
  }

  async checkExistingRsvp() {
    const rsvp = await this.storage.getRsvpForEvent(this.eventIdValue);
  }

  toggleGuestCount() {
    if (this.hasGuestCountTarget) {
      this.guestCountTarget.style.display = this.statusTarget.value === "yes" ? "block" : "none";
    }
  }
}
