import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["loader"];

  initialize() {
    console.log("INITIALISING INFINTIE SCROLL CONTROLLER::::::", this.element.dataset, this.loaderTargets);
    this.handleTurboStreamLoad = this.handleTurboStreamLoad.bind(this);
    this.handleTurboStreamFetchRequest = this.handleTurboStreamFetchRequest.bind(this);
  }

  connect() {
    // this.createIntersectionObserver();
    document.addEventListener("turbo:before-fetch-request", this.handleTurboStreamFetchRequest);
    document.addEventListener("turbo:before-stream-render", this.handleTurboStreamLoad);
  }

  disconnect() {
    document.addEventListener("turbo:before-fetch-request", this.handleTurboStreamFetchRequest);
    document.removeEventListener("turbo:before-stream-render", this.handleTurboStreamLoad);
  }

  handleTurboStreamFetchRequest(event) {
    console.log("handleTurboStreamFetchRequest:::::: ", event, this.element);
    const streamElement = event.target;

    // Check if the stream element has a target frame
    const turboFrameTarget = streamElement.getAttribute("id");

    const feedType = this.element.dataset.feedType;

    // If feed_type matches the turbo frame target
    if (`${feedType}_pagination` === turboFrameTarget) {
      // Add the hidden class to the loader target
      this.loaderTarget.classList.remove("hidden");
      console.log("Loader revealed for controller with feed_type:", feedType);
    }
  }

  handleTurboStreamLoad(event) {
    console.log("handleTurboStreamLoad:::::: ", event, this.element);
    const streamElement = event.target;

    // Check if the stream element has a target frame
    const turboFrameTarget = streamElement.getAttribute("target");

    const feedType = this.element.dataset.feedType;

    // If feed_type matches the turbo frame target
    if (`${feedType}_pagination` === turboFrameTarget) {
      // Add the hidden class to the loader target
      this.loaderTarget.classList.add("hidden");
      console.log("Loader hidden for controller with feed_type:", feedType);
    }
  }

  // ... (rest of the code remains the same)
}
