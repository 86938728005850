import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  submit() {
    // Debounce the submit to avoid too many requests
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.element.requestSubmit();
    }, 300);
  }

  clear() {
    this.inputTarget.value = "";
    this.element.requestSubmit();
  }
}
